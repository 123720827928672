<template>
  <div class="grid nogutter flex-column">
    <div class="col my-3 text-center">
      <img
        alt="Capital2Market Logo"
        src="@/assets/c2m-logo.png"
        class="make-investment-logo"
      />
    </div>
    <Card class="my-4 mx-6 py-2 px-4">
      <template #title>Document Signature</template>
      <template #content>
        <div class="grid nogutter flex-column">
          <!-- Loading -->
          <div v-if="loading" class="col mt-5 text-center">
            <ProgressSpinner
              style="width: 100px; height: 100px"
              strokeWidth="2"
            />
          </div>
          <!-- End Looading -->

          <!-- HelloSign Document -->
          <div
            class="col"
            :class="{ 'hide-document': !documentReady || documentSigned }"
          >
            <div
              id="hellosign-doc-container"
              class="v1-signature-container"
            ></div>
          </div>
          <!-- End HelloSign Document -->

          <!-- Document Signed -->
          <div
            v-if="documentSigned"
            class="flex col mt-5 align-items-center justify-content-center"
          >
            <div class="grid nogutter flex-column" style="max-width: 420px">
              <div class="col text-center">
                <i class="fas fa-thumbs-up fa-3x c2m-dark-blue-color"></i>
              </div>
              <div class="col text-center">
                You have completed and signed your investment documents.<br />
                If this is a joint investment, any secondary signers will
                receive an email to complete their portion of the documents.
              </div>
            </div>
          </div>
          <!-- End Document Signed -->

          <!-- Error -->
          <div v-if="documentError" class="col mt-5">
            <div class="grid nogutter flex-column">
              <div class="col text-center">
                <i
                  class="fas fa-exclamation-triangle fa-3x c2m-error-color"
                ></i>
              </div>
              <div class="col text-center">
                There was an error loading signing documents. Please try again
                or contact support.
              </div>
            </div>
          </div>
          <!-- End Error -->
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import HelloSign from "hellosign-embedded";
import { HelloSignEvent } from "@/interfaces/hellosign/Event";

import { Api } from "@/api";
import { InvestmentDocumentsResp } from "@/api/services/v1HelloSign";

export default defineComponent({
  name: "InvestNowSignature",
  components: {},
  setup() {
    const route = useRoute();
    const $api = inject("$api") as Api;

    let signatureRequestId = "";
    let signatureId = "";

    const loading = ref(true);
    const documentReady = ref(false);
    const documentSigned = ref(false);
    const documentError = ref(false);

    const loadDocument = async () => {
      loading.value = true;
      documentReady.value = false;
      documentError.value = false;

      try {
        let { data }: { data: InvestmentDocumentsResp } =
          await $api.v1HelloSign.requestDocumentSignature(
            signatureRequestId,
            signatureId
          );
        console.log(data);

        documentReady.value = true;

        const client = new HelloSign();
        client.on("message", hsDocumentMessage);
        client.on("sign", hsDocumentSign);
        client.on("ready", hsDocumentReady);
        client.on("decline", hsDocumentDecline);
        client.on("cancel", hsDocumentCancel);
        client.on("error", hsDocumentError);

        client.open(data.doc_url, {
          clientId: data.client_id,
          skipDomainVerification: process.env.VUE_APP_HS_SKIP_DOMAIN === "true", //true,
          container: document.getElementById(
            "hellosign-doc-container"
          ) as HTMLElement,
        });
      } catch (e) {
        console.error(e);
        documentError.value = true;
      } finally {
        loading.value = false;
      }
    };

    // have to use any here since the interface can not be imported nor cast to a different type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hsDocumentMessage = (data: any) => {
      console.log(`hsDocumentMessage: ${data}`);
      console.log(data);
      // if (data.type === HelloSign.messages.APP_INITIALIZE) {}
    };

    const hsDocumentSign = (data: HelloSignEvent) => {
      console.log(`hsDocumentSign: ${data}`);
      documentSigned.value = true;
    };

    const hsDocumentReady = (data: HelloSignEvent) => {
      console.log(`hsDocumentRead: ${data}`);
    };

    const hsDocumentDecline = (data: HelloSignEvent) => {
      console.log(`hsDocumentDecline: ${data}`);
    };

    const hsDocumentCancel = () => {
      console.log(`hsDocumentCancel`);
    };

    const hsDocumentError = (data: HelloSignEvent) => {
      console.log(`hsDocumentError: ${data}`);
    };

    // lifecycle hooks
    onMounted(() => {
      signatureRequestId = route.params.id as string;
      console.log(signatureRequestId);

      signatureId = route.params.sid as string;
      console.log(signatureId);

      loadDocument();
    });

    return {
      loading,
      documentReady,
      documentSigned,
      documentError,
    };
  },
});
</script>

<style lang="scss" scoped>
.make-investment-logo {
  max-width: 400px;
  max-height: 120px;
  mix-blend-mode: multiply;
}

.make-investment-title-container {
  background-color: #60879f;
}

.make-investment-title {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.hide-document {
  display: none;
}
</style>
