
import { defineComponent, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import HelloSign from "hellosign-embedded";
import { HelloSignEvent } from "@/interfaces/hellosign/Event";

import { Api } from "@/api";
import { InvestmentDocumentsResp } from "@/api/services/v1HelloSign";

export default defineComponent({
  name: "InvestNowSignature",
  components: {},
  setup() {
    const route = useRoute();
    const $api = inject("$api") as Api;

    let signatureRequestId = "";
    let signatureId = "";

    const loading = ref(true);
    const documentReady = ref(false);
    const documentSigned = ref(false);
    const documentError = ref(false);

    const loadDocument = async () => {
      loading.value = true;
      documentReady.value = false;
      documentError.value = false;

      try {
        let { data }: { data: InvestmentDocumentsResp } =
          await $api.v1HelloSign.requestDocumentSignature(
            signatureRequestId,
            signatureId
          );
        console.log(data);

        documentReady.value = true;

        const client = new HelloSign();
        client.on("message", hsDocumentMessage);
        client.on("sign", hsDocumentSign);
        client.on("ready", hsDocumentReady);
        client.on("decline", hsDocumentDecline);
        client.on("cancel", hsDocumentCancel);
        client.on("error", hsDocumentError);

        client.open(data.doc_url, {
          clientId: data.client_id,
          skipDomainVerification: process.env.VUE_APP_HS_SKIP_DOMAIN === "true", //true,
          container: document.getElementById(
            "hellosign-doc-container"
          ) as HTMLElement,
        });
      } catch (e) {
        console.error(e);
        documentError.value = true;
      } finally {
        loading.value = false;
      }
    };

    // have to use any here since the interface can not be imported nor cast to a different type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hsDocumentMessage = (data: any) => {
      console.log(`hsDocumentMessage: ${data}`);
      console.log(data);
      // if (data.type === HelloSign.messages.APP_INITIALIZE) {}
    };

    const hsDocumentSign = (data: HelloSignEvent) => {
      console.log(`hsDocumentSign: ${data}`);
      documentSigned.value = true;
    };

    const hsDocumentReady = (data: HelloSignEvent) => {
      console.log(`hsDocumentRead: ${data}`);
    };

    const hsDocumentDecline = (data: HelloSignEvent) => {
      console.log(`hsDocumentDecline: ${data}`);
    };

    const hsDocumentCancel = () => {
      console.log(`hsDocumentCancel`);
    };

    const hsDocumentError = (data: HelloSignEvent) => {
      console.log(`hsDocumentError: ${data}`);
    };

    // lifecycle hooks
    onMounted(() => {
      signatureRequestId = route.params.id as string;
      console.log(signatureRequestId);

      signatureId = route.params.sid as string;
      console.log(signatureId);

      loadDocument();
    });

    return {
      loading,
      documentReady,
      documentSigned,
      documentError,
    };
  },
});
